import React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import ServicePage from "../../components/service";

export default function SiteLiaisonPage() {
    const {service} = useStaticQuery(graphql`
        query {
          service: servicesJson(key: {eq: "site-liaison"}) {
            title
            title_long
            thumbnail {
              path {
                childImageSharp {
                  gatsbyImageData(width: 1024)
                }
              }
            }
            projects {
              title
              client
              location
            }
          }
        }
    `)
    return (
        <ServicePage service={service}>
            <p>
                REC has acted as site liaison between the client and the regulator in Banff National Park on two long-term projects which has helped to facilitate decision-making, understanding and project completion.  REC believes this is an essential component for any project occurring on sensitive lands.
            </p>
        </ServicePage>
    )
}
