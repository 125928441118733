import React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Page from './page';
import Seo from './seo';
import Nav from './nav';
import Section from './section';



export default function ServicePage({service, children}) {
    const {clients} = useStaticQuery(graphql`
      query {
        clients: allClientsJson {
            nodes {
                key
                title
                logo {
                    childImageSharp {
                        gatsbyImageData(layout: FIXED, width: 150)
                    }
                }
            }
        }
      }
    `)
    const clientsByKey = clients.nodes.reduce((obj, n) => {
        obj[n.key] = n;
        return obj;
    }, {});

    return (
        <Page>
            <Seo title={service.title}/>
            <Nav/>
            <Section>
                <div className="flex flex-wrap">
                    <div className="w-full md:w-1/3 md:px-8 lg:px-16">
                        <GatsbyImage alt={service.title_long} image={getImage(service.thumbnail.path)} className=" border-primary rounded drop-shadow-lg mb-2"/>
                    </div>
                    <div className="w-full px-2 md:w-2/3">
                        <h1 className="text-2xl md:text-4xl text-primary font-black mb-2">{service.title_long}</h1>
                        <hr className="border-2 border-secondary"/>
                        <div className="text-xl font-thin mt-4">
                            {children}
                        </div>
                    </div>
                </div>
            </Section>
            <Section>
                <h2 className="text-xl md:text-3xl text-primary font-black mb-2">Relevant Projects</h2>
                <hr className="border-2 text-secondary"/>
                <div className="flex justify-evenly flex-wrap">
                    {service.projects.filter(p => !!p.client).map((p,i) => {
                        const client = clientsByKey[p.client];
                        return (
                            <div key={i} className="w-full md:w-1/2 flex flex-col md:flex-row p-4 md:p-8 border-0 border-black">
                                {client && <GatsbyImage alt={client.title} image={getImage(client.logo)} />}
                                <div className="flex-1 pl-2">
                                    <p className="">{p.title}</p>
                                    {p.location && <p className="text-sm font-thin">{p.location}</p> }
                                    {client && <p className="text-xs font-thin italic">{client.title}</p>}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Section>

        </Page>
    )
}
